import { Pane } from "evergreen-ui";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ViewWrapper } from "../common/ViewWrapper";
import { fetchYearlyOverview } from "../transactions/slices/transactionsSlice";
import YearlyOverviewGrid from "./YearlyOverviewGrid";
import {
  YearlyOverview as YearlyOverviewType,
  YearlyOverviewResponse,
} from "../api/crudTransactions";

const YearlyOverview = () => {
  const dispatch = useDispatch();
  const [selectedYear, setSelectedYear] = useState(2024);
  const [selectedMonth, setSelectedMonth] = useState<number>(
    new Date().getMonth() + 1
  );

  useEffect(() => {
    dispatch(
      fetchYearlyOverview({ year: selectedYear, untilMonth: selectedMonth })
    );
  }, [dispatch, selectedYear, selectedMonth]);

  const getAvailableMonths = (year: number) => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;

    const months = [
      "1 januari -> 31 januari",
      "1 januari -> 29 februari",
      "1 januari -> 31 mars",
      "1 januari -> 30 april",
      "1 januari -> 31 maj",
      "1 januari -> 30 juni",
      "1 januari -> 31 juli",
      "1 januari -> 31 augusti",
      "1 januari -> 30 september",
      "1 januari -> 31 oktober",
      "1 januari -> 30 november",
      "1 januari -> 31 december",
    ];

    if (year < currentYear) {
      return months.map((month, index) => ({
        value: index + 1,
        label: month,
      }));
    }

    if (year === currentYear) {
      return months.slice(0, currentMonth).map((month, index) => ({
        value: index + 1,
        label: month,
      }));
    }

    return [];
  };

  const startSumOverrides =
    selectedYear === 2022
      ? {
          "fredrik.lindroth@sprinto.se": 202583,
          "carl-johan.danver@sprinto.se": -16200,
          "henning.bergstrom@sprnto.se": 51060,
          "kisslo.shek@sprinto.se": 0.1,
        }
      : selectedYear === 2023
      ? {
          "jens.palmqvist@sprinto.se": -211671,
        }
      : {};

  const endSumOverrides =
    selectedYear === 2023
      ? {
          "mikael.lettestad@sprinto.se": 0.1,
          "joel.janson.johansen@sprinto.se": 0.1,
        }
      : {};

  const excludeEmails = {
    2023: [
      "mikaela.liss@sprinto.se",
      "jakob.ekbrandt@sprinto.se",
      "jonatan.hallenberg@sprinto.se",
    ],
    2024: ["jonatan.hallenberg@sprinto.se"],
  };

  const yearlyOverview: YearlyOverviewResponse = useSelector(
    (state: any) => state.transaction.yearlyOverview
  ) as YearlyOverviewResponse;

  console.log("yearlyOverview", yearlyOverview);
  const yearlyOverviewData = yearlyOverview.data
    .filter(
      (yo: YearlyOverviewType) =>
        !excludeEmails[selectedYear as keyof typeof excludeEmails]?.includes(
          yo.email
        )
    )
    .map((yo: any) => ({
      ...yo,
      startSum:
        startSumOverrides[yo.email as keyof typeof startSumOverrides] ||
        yo.startSum,
      endSum:
        endSumOverrides[yo.email as keyof typeof endSumOverrides] || yo.endSum,
    }));

  return (
    <ViewWrapper title="Årlig översikt">
      <Pane marginTop="20px" display="block">
        <Pane display="flex" gap={16} marginBottom={16}>
          <select
            value={selectedYear}
            onChange={(e) => {
              const newYear = parseInt(e.target.value);
              setSelectedYear(newYear);
              const currentYear = new Date().getFullYear();

              if (
                newYear === currentYear &&
                selectedMonth > new Date().getMonth() + 1
              ) {
                setSelectedMonth(new Date().getMonth() + 1);
              } else if (newYear < currentYear) {
                setSelectedMonth(12);
              }
            }}
          >
            <option value="2021">2021</option>
            <option value="2022">2022</option>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
            <option value="2025">2025</option>
          </select>

          <select
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
          >
            {getAvailableMonths(selectedYear).map((month) => (
              <option key={month.value} value={month.value}>
                {month.label}
              </option>
            ))}
          </select>
        </Pane>

        <YearlyOverviewGrid
          yearlyOverview={{
            data: yearlyOverviewData,
            meta: {
              year: yearlyOverview.meta.year,
              untilMonth: yearlyOverview.meta.untilMonth,
            },
          }}
        />
      </Pane>
    </ViewWrapper>
  );
};

export default YearlyOverview;
