import { Pane, Tab } from "evergreen-ui";
import styled, { css } from "styled-components";

export const Wrapper = styled(Pane)`
  display: flex;
  flex-direction: row;
  position: relative;
  border-bottom: 1px dotted #ccc;
  border-top: 1px dotted #ccc;
  margin-top: 10px;
  width: 100%;
  justify-content: space-between;
`;

export const TabContainer = styled(Pane)`
  ${(props) => props.float && `float: ${props.float}`};
  display: flex;
  gap: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const TabPadding = css`
  padding-left: 10px;
  padding-right: 10px;
`;

export const DefaultTab = styled(Tab)`
  ${TabPadding}
  ${(props) =>
    props.$inverted &&
    `
    background-color: #888;
    color: white;
  &[aria-selected="true"], &[aria-selected="true"]:hover {
    background-color: #444;
    color: white;
  }
  
   
  
  &:hover {
    background-color: #555;
    color: white;
  }`}
`;
