import { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import {
  Button,
  Menu as EvergreenMenu,
  Heading,
  MoreIcon,
  Pane,
  Popover,
  Position,
} from "evergreen-ui";
import { motion, useAnimation } from "framer-motion";
import "./App.css";
import authenticationSlice from "./app/authenticationSlice";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { setImpersonateUser } from "./app/slices/appSlice";
import { ErrorMessage } from "./common/ErrorMessage/ErrorMessage";
import Greeting from "./common/Greeting/Greeting";
import { Menu } from "./common/Menu";
import EmployeeList from "./employees/EmployeeView";
import ProjectListView from "./projects/ProjectListView";
import ProjectDetailsView from "./projects/ProjectDetailsView";
import transactionsSlice from "./transactions/slices/transactionsSlice";
import VismaImport from "./visma-import/VismaImportView";

import { fetchEmployees } from "./employees/slices/employeeSlice";

import { IsAdminRoute } from "./common/IsAdminRoute";
import TimeReportView from "./time-report/TimeReportView";
import TransactionsView from "./transactions/TransactionsView";
import { Employee } from "./types";
import YearlyOverview from "./yearlyoverview/YearlyOverview";
import { fetchProjectsByUser } from "./projects/slices/projectSlice";
import { fetchAllProjects } from "./projects/slices/projectSlice";
import { getVismaToken } from "./api/getVismaToken";

type AppProps = {
  accountInfo: any;
  onLogout: () => any;
};

const App = ({ accountInfo, onLogout }: AppProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const titleControls = useAnimation();
  const contentControls = useAnimation();
  const isAdmin = useAppSelector((state) => state.authentication.isAdmin);

  const employees: Employee[] = useAppSelector(
    (state: any) => state.employee.employees
  );
  const [currentUser, setCurrentUser] = useState<any>();

  const impersonateUser = useAppSelector(
    (state: any) => state.app.impersonateUser
  );

  useEffect(() => {
    const fetchToken = async (code: string) => {
      console.log("fetching token");
      const response = await getVismaToken(accountInfo.jwtIdToken, code);
      console.log("response", await response.json());
    };
    const query = new URLSearchParams(location.search);
    const code = query.get("code");
    if (code) {
      fetchToken(code);
    }
  }, [accountInfo.jwtIdToken, location.search]); // körs när querystring ändras

  useEffect(() => {
    dispatch(
      authenticationSlice.actions.setUser({
        name: accountInfo.account.name,
        jwtIdToken: accountInfo.jwtIdToken,
        email: accountInfo.account.userName,
      })
    );
  }, [accountInfo, dispatch]);

  useEffect(() => {
    setCurrentUser(
      impersonateUser
        ? impersonateUser
        : accountInfo && accountInfo.account.name
        ? {
            firstname: accountInfo.account.name.split(" ")[0],
            lastname: accountInfo.account.name.split(" ")[1],
            email: accountInfo.account.userName,
          }
        : null
    );
    dispatch(transactionsSlice.actions.resetState());
  }, [impersonateUser]);

  useEffect(() => {
    if (currentUser) {
      if (isAdmin) {
        dispatch(fetchAllProjects());
      } else {
        dispatch(fetchProjectsByUser(currentUser));
      }
    }
  }, [dispatch, currentUser, isAdmin]);

  useEffect(() => {
    titleControls.start({
      scale: [0, 3, 1],
      marginTop: ["40vh", "40vh", "2vh"],
      type: "spring",
    });
  }, [titleControls]);

  useEffect(() => {
    contentControls.start({
      opacity: [0, 1],
    });
  }, [contentControls]);

  useEffect(() => {
    if (isAdmin) {
      dispatch(fetchEmployees());
    }
  }, [dispatch, isAdmin]);

  return (
    <Pane marginX="auto" maxWidth="1000px" marginTop="40px">
      <motion.div
        style={{ opacity: 0 }}
        animate={contentControls}
        transition={{
          duration: 3,
          ease: "easeInOut",
          times: [0, 1],
          delay: 0,
        }}
      >
        <Heading
          size={900}
          marginBottom="20px"
          color="white"
          textAlign="center"
        >
          {currentUser && (
            <>
              <Greeting
                name={`${currentUser.firstname}${
                  isAdmin && " " + currentUser.lastname
                }`}
              />
              {isAdmin && (
                <Popover
                  position={Position.BOTTOM_LEFT}
                  content={({ close }) => (
                    <EvergreenMenu>
                      <EvergreenMenu.Group>
                        {employees.map((employee) =>
                          employee.active ? (
                            <EvergreenMenu.Item
                              key={employee.id}
                              onSelect={() => {
                                close();
                                dispatch(setImpersonateUser(employee));
                              }}
                            >
                              <span
                                title={`${employee.firstname} ${employee.lastname}`}
                              >
                                {employee.firstname}
                              </span>
                            </EvergreenMenu.Item>
                          ) : null
                        )}
                      </EvergreenMenu.Group>
                    </EvergreenMenu>
                  )}
                >
                  <Button
                    marginLeft={5}
                    padding={5}
                    fontSize={8}
                    appearance="minimal"
                    intent="none"
                  >
                    <MoreIcon />
                  </Button>
                </Popover>
              )}
            </>
          )}
        </Heading>
        <Pane textAlign="center" marginBottom="20px">
          <Menu>
            <Menu.Item
              title="Personlig balansräkning"
              onSelect={() => navigate("/pbr")}
              isSelected={location.pathname === "/pbr"}
            ></Menu.Item>
            <Menu.Item
              title="Timmar"
              onSelect={() => navigate("/hours")}
              isSelected={location.pathname === "/hours"}
            ></Menu.Item>
            {isAdmin ? (
              <Menu.Item
                title="Admin"
                isSelected={location.pathname === "/project"}
                onSelect={() => {}}
              >
                <Menu.SubItem
                  title="Projekt"
                  onSelect={() => navigate("/project")}
                ></Menu.SubItem>
                <Menu.SubItem
                  title="Anställda"
                  onSelect={() => navigate("/employee")}
                ></Menu.SubItem>
                <Menu.SubItem
                  title="Årlig översikt"
                  onSelect={() => navigate("/yearlyoverview")}
                ></Menu.SubItem>
                <Menu.SubItem
                  title="Importera från Visma"
                  onSelect={() => navigate("/visma-import")}
                ></Menu.SubItem>
              </Menu.Item>
            ) : (
              <></>
            )}
          </Menu>
        </Pane>
        <Routes>
          <Route
            path="/pbr"
            element={
              <TransactionsView
                jwtToken={accountInfo.jwtIdToken}
                user={currentUser}
              />
            }
          />
          <Route
            path="/hours"
            element={
              <TimeReportView
                jwtToken={accountInfo.jwtIdToken}
                user={currentUser}
              />
            }
          />
          <Route path="/" element={<Navigate to="/pbr" />} />
          <Route
            path="/project"
            element={
              <IsAdminRoute>
                <ProjectListView user={currentUser} />
              </IsAdminRoute>
            }
          />
          <Route
            path="/employee"
            element={
              <IsAdminRoute>
                <EmployeeList
                  jwtToken={accountInfo.jwtIdToken}
                  user={currentUser}
                />
              </IsAdminRoute>
            }
          />
          <Route
            path="/visma-import"
            element={
              <IsAdminRoute>
                <VismaImport jwtToken={accountInfo.jwtIdToken} />
              </IsAdminRoute>
            }
          />
          <Route
            path="/yearlyoverview"
            element={
              <IsAdminRoute>
                <YearlyOverview />
              </IsAdminRoute>
            }
          />
          <Route path="/project/:projectId" element={<ProjectDetailsView />} />
          <Route
            path="*"
            element={<ErrorMessage title={"404 - Sidan finns inte."} />}
          />
        </Routes>
      </motion.div>
    </Pane>
  );
};

export default App;
