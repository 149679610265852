import { Button, Pane, Spinner } from "evergreen-ui";
import { ViewWrapper } from "../common/ViewWrapper";
import VismaImportTable from "./VismaImportTable";
import { TimespanSelector } from "../common/TimespanSelector";
import { DateFilter } from "../types/index";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import { useEffect } from "react";
import {
  setYear,
  setMonth,
  setStatus,
  fetchVismaImport,
  toggleSelectItem,
  toggleSelectAllItems,
  isAllSelectedSelector,
  saveTransaction,
  sortAndToggleOrder,
} from "./slices/vismaImportSlice";
import { RefreshIcon } from "evergreen-ui";

type VismaImportViewType = {
  jwtToken: string;
};

type YearMonths = {
  [key: string]: number[];
};

const getYearMonthsFromStartUntilNow = (
  startYear: number,
  startMonth: number
): YearMonths => {
  const endDate = new Date();

  // Return the year month object of all years and their months from startDate to endDate

  const yearMonths: YearMonths = {};
  for (let year = startYear; year <= endDate.getFullYear(); year++) {
    const months = [];
    for (
      let month = year === startYear ? startMonth : 1;
      month <= (year === endDate.getFullYear() ? endDate.getMonth() + 1 : 12);
      month++
    ) {
      months.push(month);
    }
    yearMonths[year] = months;
  }

  return yearMonths;
};

const yearMonths: YearMonths = getYearMonthsFromStartUntilNow(2022, 1);

const VismaImportView = ({ jwtToken }: VismaImportViewType) => {
  const dispatch = useAppDispatch();

  const filter = useAppSelector((state) => state.vismaImport.filter);
  const vismaImportItems = useAppSelector((state) => state.vismaImport.items);
  const isLoading = useAppSelector((state) => state.vismaImport.isLoading);
  const allSelected = !!useAppSelector(isAllSelectedSelector);
  const selectedItems = vismaImportItems.filter((item) => item.selected);

  const renderSelectedButtons = () => (
    <>
      <button
        onClick={() => saveSelected()}
        type="button"
        className="text-white   bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
      >
        Importera
      </button>
      {/* <button type="button" className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Dölj</button> */}
    </>
  );

  const renderRefreshButton = () => (
    <>
      <button
        onClick={() => dispatch(fetchVismaImport(filter))}
        type="button"
        className="py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
      >
        <RefreshIcon size={20} />
      </button>
    </>
  );

  const saveSelected = () => {
    selectedItems.forEach((item) => dispatch(saveTransaction(item)));
  };

  useEffect(() => {
    dispatch(fetchVismaImport(filter));
  }, [filter, dispatch]);

  return (
    <ViewWrapper title="Importera från Visma">
      <TimespanSelector
        onChange={(newFilter: DateFilter) => {
          dispatch(setYear(newFilter.year));
          dispatch(setMonth(newFilter.month));
        }}
        yearMonths={yearMonths}
        filter={filter}
        selectAllMonth={false}
      />
      {vismaImportItems.length > 0 && selectedItems.length === 0 && (
        <div
          className="items-center p-4 mt-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400 flex"
          role="alert"
        >
          <div className="grow font-medium">Markera för att välja åtgärd</div>
          <div>{renderRefreshButton()}</div>
        </div>
      )}
      {vismaImportItems.length === 0 && (
        <div
          className="items-center p-4 mt-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400 flex"
          role="alert"
        >
          <div className="grow font-medium">Inget att importera</div>
          <div>{renderRefreshButton()}</div>
        </div>
      )}
      {selectedItems.length > 0 && (
        <div
          className="items-center p-4 mt-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400 flex"
          role="alert"
        >
          <div className="grow font-medium">{selectedItems.length} valda</div>
          <div>{renderSelectedButtons()}</div>
        </div>
      )}
      {vismaImportItems.length > 0 && (
        <Pane marginTop="20px" display="block">
          <VismaImportTable
            transactions={vismaImportItems}
            isLoading={isLoading}
            onSelectItem={(transaction) =>
              dispatch(toggleSelectItem(transaction.sourceReference))
            }
            onSelectAllItems={() => dispatch(toggleSelectAllItems())}
            allSelected={allSelected}
            onSort={(field: "PBR" | "Date") =>
              dispatch(sortAndToggleOrder(field))
            }
          />
        </Pane>
      )}
    </ViewWrapper>
  );
};

export default VismaImportView;
